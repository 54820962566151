import React, { Component } from 'react'

class Testimonial extends Component {
  render() {
    return (
      <div className='post-block post-block--testimonial'>
        <div className="post-block__testimonial">
          <div className="post-block__testimonial-quote" dangerouslySetInnerHTML={{__html: this.props.quote }} />
          <p className="post-block__testimonial-name">—{this.props.name}</p>
        </div>
      </div>
    )
  }
}

export default Testimonial
