import React, { Component } from 'react'

class Stats extends Component {
  render() {
    return (
      <div className='post-block post-block--stats'>
        <div className="post-block__stats">
          <div className="post-block__number">
            <span className="number">{this.props.number}</span>
            <span className="symbol">{this.props.symbol}</span>
          </div>
          <div className="post-block__stats-description" dangerouslySetInnerHTML={{__html: this.props.description }} />
        </div>
      </div>
    )
  }
}

export default Stats
