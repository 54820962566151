import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import he from 'he'
import moment from 'moment'

import SEO from '../components/seo'
import Subscribe from '../components/subscribe'
import Content from '../components/post/content'
import Image from '../components/post/image'
import VideoEmbed from '../components/post/video-embed'
import VideoId from '../components/post/video-id'
import Stats from '../components/post/stats'
import Testimonial from '../components/post/testimonial'
import BlockQuote from '../components/post/blockquote'
import SocialShare from '../components/social-share'
import WorkCard from '../components/cards/work-card'
import PageCover from '../components/page-cover'

import { Close } from '../components/icons'

import shuffle from '../utils/shuffle-array'

class ProjectTemplate extends Component {

  ticking = false

  state = {
    visible: false,
    filter: 'All',
    mobileToggle: false,
    scrolled: false,
    related: shuffle(this.props.data.related.edges).slice(0, 4),
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll = () => {
    this.requestTick()
  }

  requestTick = () => {
    if (!this.ticking) {
      window.requestAnimationFrame(this.scrollDetect)
      this.ticking = true
    }
  }

  scrollDetect = () => {
    // Handle scroll state
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { scrolled } = this.state

    if (currentScroll > 0 && this.lastScroll <= currentScroll) {
      if (!scrolled) {
        this.setState({ scrolled: true })
      }
    } else {
      if (scrolled) {
        this.setState({ scrolled: false })
      }
    }
    this.lastScroll = currentScroll

    // Handle split scrolling
    let $cont = document.querySelector('.project__wrapper')
    let $info = document.querySelector('.project__sticky')

    let viewport = window.innerHeight
    let scrollPercent = (window.scrollY - viewport) / ($cont.clientHeight)
    if (scrollPercent < 0) scrollPercent = 0
    if (scrollPercent > 1) scrollPercent = 1

    scrollPercent = Math.round(scrollPercent * 10000) / 10000
    scrollPercent = Math.min(scrollPercent, 0.95)

    if (window.innerWidth >= 920)
      $info.style.transform = `translate3d(0, ${(scrollPercent * ($cont.scrollHeight - $info.scrollHeight))}px, 0)`
    else
      $info.style.transform = null

    this.ticking = false
  }

  _toggleFilter = () => {
    if ( this.state.visible === true ) {
      this.setState({visible: false})
    } else {
      this.setState({visible: true})
    }
  }

  _toggleMobileContent = () => {
    if ( this.state.mobileToggle === true ) {
      this.setState({mobileToggle: false})
    } else {
      this.setState({mobileToggle: true})
    }
  }

  renderBlock(param, el, i) {
    let block = {
      'WordPressAcf_content': (el, i) => (<Content {...el} key={i} />),
      'WordPressAcf_image': (el, i) => (<Image {...el} key={i} />),
      'WordPressAcf_video': (el, i) => (<VideoEmbed {...el} key={i} />),
      'WordPressAcf_blockquote': (el, i) => (<BlockQuote {...el} id={i} key={i} />),
      'WordPressAcf_testimonial': (el, i) => (<Testimonial {...el} id={i} key={i} />),
      'WordPressAcf_stats': (el, i) => (<Stats {...el} id={i} key={i} />),
      'WordPressAcf_video_id': (el, i) => (<VideoId {...el} key={i} />),
    }[param]

    if (!block) return

    return block(el, i)
  }

  _renderPost = (data, i=0) => {
    //let featuredImage = data.acf.featured_image && data.acf.featured_image.localFile && data.acf.featured_image.localFile.childImageSharp.original.src
    //let date = moment(data.date).format('MMM, YYYY')
    let mobileToggleClasses = `
      ${ this.state.mobileToggle ? 'project__mobile-toggle active' : 'project__mobile-toggle' }
      ${ this.state.scrolled ? 'scrolled' : '' }
    `.replace(/\n/g, '')
    let stickyClasses = `
      ${this.state.mobileToggle ? 'project__sticky active' : 'project__sticky'}
      ${ this.state.scrolled ? 'scrolled' : '' }
		`.replace(/\n/g, '')

    return (
      <>
        <div className="project" key={i}>
          <div className={ mobileToggleClasses } onClick={this._toggleMobileContent}>
            <h4>Learn more about this project <Close colour="#707070" /></h4>
          </div>
          <div className='project__wrapper container'>
            <div className="project__sidebar">
              <div className={ stickyClasses }>
                { data.acf.overview.map((el, i) => (
                  <div className="project__overview" key={i}>
                    <h3>{el.title}</h3>
                    <div className="project__overview-content" dangerouslySetInnerHTML={{__html: el.content }} />
                  </div>
                )) }

                <div className="project__category first">
									<h4>Sector</h4>
                  { data.sector &&
                    <ul>
                      { data.sector.map((el, i) => <li key={i}><Link to={`/services/${el.slug}`}>{el.name}</Link></li>) }
                    </ul>
                  }
                </div>

                <div className="project__category">
									<h4>Discipline</h4>
                  { data.categories &&
                    <ul>
                      { data.categories.map((el, i) => <li key={i}><Link to={`/services/${el.slug}`}>{el.name}</Link></li>) }
                    </ul>
                  }
                </div>

                <SocialShare title={data.title} />
              </div>
            </div>
            <div className="project__body">
              <div className='project__inner'>
                { data.acf.content_blocks_work && data.acf.content_blocks_work.map((el, i) => {
                  return this.renderBlock(el.__typename, el, i)
                }) }
              </div>
              <SocialShare title={data.title} />
            </div>
          </div>
        </div>
        <section className='project__related'>
          <div className='project__related__inner container'>
            <div className='col col--1of3'>
              <h3>You might also be interested in</h3>
            </div>
            <div className='col col--2of3'>
              { this.state.related.map((el, i) => {
                let props = {
                  image: el.node.acf.featured_image && el.node.acf.featured_image.localFile && el.node.acf.featured_image.localFile.childImageSharp.original.src,
                  title: el.node.title,
                  link: `/work/${el.node.slug}`,
                  author: el.node.acf.author_name,
                  date: moment(el.node.date).format("MMM, YYYY")
                }
                return <WorkCard {...props} key={i} />
              }) }
            </div>
          </div>
        </section>
      </>
    )
  }

  render() {
    //let { visible, filter } = this.state
    let data = this.props.data
    return (
      <>
        <SEO title={he.decode(data.wordpressWpWork.yoast_meta.yoast_wpseo_title)} bodyClass="single-post" description={data.wordpressWpWork.yoast_meta.yoast_wpseo_metadesc} />
        { data.wordpressWpWork.acf.page_cover && <PageCover content={data.wordpressWpWork.acf.page_cover} /> }
        <div className='page-cover__under'>
          { this._renderPost(data.wordpressWpWork) }
          <Subscribe />
        </div>
      </>
    )
  }

}

export default ProjectTemplate

// TODO apply filter to related
//sector: { elemMatch: {slug: { eq: $sector } }}

export const projectQuery = graphql`
  query ($id: String!) {
    allWordpressCategory(sort: {fields: [menu_order], order: ASC}) {
      edges {
        node {
          name
          slug
        }
      }
    }
    allWordpressWpSector(sort: {fields: [menu_order], order: ASC}) {
      edges {
        node {
          name
          slug
        }
      }
    }
    related: allWordpressWpWork(filter:{
      id:{ne: $id},
    }) {
      edges {
        node {
          title
          slug
          categories {
            name
            slug
          }
          sector {
            name
            slug
          }
          acf {
            featured_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressWpWork(id: {eq: $id}) {
      id
      wordpress_id
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      categories {
        name
        slug
      }
      sector {
        name
        slug
      }
      acf {
        author_name
        page_cover
        featured_image {
          localFile {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
        overview {
          title
          content
        }
        content_blocks_work {
          __typename
          ... on WordPressAcf_image {
            image {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_stats {
            number
            symbol
            description
          }
          ... on WordPressAcf_testimonial {
            quote
            name
          }
          ... on WordPressAcf_video {
            video
            poster {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
          }
          ... on WordPressAcf_video_id {
            youtube_video_id
			video_format
            poster_image {
              alt_text
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
